import './style.css'

const SocialIcon = ({ name }) => {
    const SocialIconSvg = require(`../../assets/icons/${name}.svg`).default

    return (
        <div className="social-icon-wrapper" style={{height: '100%', width: '100%'}}>
            <img src={SocialIconSvg} alt={name} />
        </div>
    )
}

export default SocialIcon
