import { useEffect, useState } from 'react'
import ScreenWrapper from './ScreenWrapper'
import './css/screen06.css'
import ecosystem_log1 from './assest/ecosystem_log1.png'
import ecosystem_log2 from './assest/ecosystem_log2.png'
import ecosystem_log3 from './assest/ecosystem_log3.png'
import ecosystem_log4 from './assest/ecosystem_log4.png'
import ecosystem_log5 from './assest/ecosystem_log5.png'
import ecosystem_log6 from './assest/ecosystem_log6.png'
import ecosystem_log7 from './assest/ecosystem_log7.png'
import ecosystem_log8 from './assest/ecosystem_log8.png'
import ecosystem_log9 from './assest/ecosystem_log9.png'
import ecosystem_log10 from './assest/ecosystem_log10.png'
import part_logo1 from './assest/part_logo1.png'
import part_logo2 from './assest/part_logo2.png'
import part_logo3 from './assest/part_logo3.png'
import part_logo4 from './assest/part_logo4.png'
import part_logo5 from './assest/part_logo5.png'
import part_logo6 from './assest/part_logo6.png'
import part_logo7 from './assest/part_logo7.png'
import part_logo8 from './assest/part_logo8.png'
import { useTranslation } from 'react-i18next'
import QueueAnim from 'rc-queue-anim'
const Screen06 = ({ fullpageApi, fullpageclass, index, currentIndex }) => {
    const { t } = useTranslation('screen06')
    const EcoList = [
        ecosystem_log1,
        ecosystem_log2,
        ecosystem_log3,
        ecosystem_log4,
        ecosystem_log5,
        ecosystem_log6,
        ecosystem_log7,
        ecosystem_log8,
        ecosystem_log9,
        ecosystem_log10
    ]

    const partnerList = [
        part_logo1,
        part_logo2,
        part_logo3,
        part_logo4,
        part_logo5,
        part_logo6,
        part_logo7,
        part_logo8
    ]
    const [showAnimation, setShowAnimation] = useState(false)
    useEffect(() => {
        if (index === currentIndex) {
            setShowAnimation(true)
        } else {
            setShowAnimation(false)
        }
    }, [currentIndex, index])

    useEffect(() => {
        fullpageApi && fullpageApi.reBuild()
    }, [fullpageApi])
    return (
        <ScreenWrapper id="screen05" fullpageclass={fullpageclass}>
            <div className="screen06-warp">
                {index !== currentIndex ? (
                    <div className='opacity-0'>
                        <div className="screen06-warp-top">
                            <h1 className="page-title">Partership</h1>
                            <div className="screen06-warp-top-title">{t('title')}</div>
                            <div className="screen06-warp-top-logo">
                                {EcoList.map((logo, index) => (
                                    <div key={index} className={`screen06-warp-top-logo-list`}>
                                        <img className={`mx-auto`} alt="" src={logo} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="">
                            <div className="screen06-warp-bottom-partners">{t('subtitle')}</div>
                            <div className="screen06-warp-bottom-partners-list">
                                {partnerList.map((logo, i) => (
                                    <img key={i} className={`mx-auto`} alt="" src={logo} />
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <QueueAnim
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 50] },
                            { opacity: [1, 0], translateY: [0, -50] }
                        ]}
                        ease={['easeOutQuart', 'easeInOutQuart']}>
                        {showAnimation ? (
                            <div key="screen06">
                                <div className="screen06-warp-top">
                                    <div className="screen06-warp-top-title">{t('title')}</div>
                                    <div className="screen06-warp-top-logo">
                                        {EcoList.map((logo, index) => (
                                            <div
                                                key={index}
                                                className={`screen06-warp-top-logo-list`}>
                                                <img className={`mx-auto`} alt="" src={logo} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <div className="screen06-warp-bottom-partners">
                                        {t('subtitle')}
                                    </div>
                                    <div className="screen06-warp-bottom-partners-list">
                                        {partnerList.map((logo, i) => (
                                            <img key={i} className={`mx-auto`} alt="" src={logo} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </QueueAnim>
                )}
            </div>
        </ScreenWrapper>
    )
}

export default Screen06
