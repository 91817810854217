/* This example requires Tailwind CSS v2.0+ */

import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'
// import { MenuIcon, XIcon } from '@heroicons/react/outline'
import RealyLogo from '../../assets/images/realy_logo.svg'
import { SocialLinks } from '../../constants/urls'
import SocialIcon from '../SocialIcon'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Download from '../DownloadAPP'
import './index.css'
const navigation = [
    { name: 'home', href: '/', current: false },
    { name: 'Siya.AI', href: 'https://www.siya.ai', current: false },
    { name: 'marketPlace', href: 'https://kooola.io/', current: false }
    // { name: 'airdrop', href: 'https://airdrop.realy.pro/', current: false }
    // { name: 'Calendar', href: '#', current: false }
]

const showSocialList = ['twitter', 'discord', 'medium', 'telegram', 'instagram']

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TopMenu() {
    const [lng, setLng] = useState('EN')
    const { t, i18n } = useTranslation('common')
    const [showDownLoadModal, setShowDownLoad] = useState(false)
    function changeLanguage(lng) {
        i18n.changeLanguage(lng.toLowerCase())
        setLng(lng)
    }
    const downLoadModal = () => {
        setShowDownLoad(!showDownLoadModal)
    }
    return (
        <Disclosure as="nav" className="topmenu">
            {({ open }) => (
                <>
                    <div className="flex lg:block w-screen relative h-full px-5 lg:px-9 -mx-px">
                        <div className="flex w-full h-full items-center md:justify-between">
                            <div className="flex items-center flex-shrink-0">
                                <img
                                    className="block w-auto h-8 lg:hidden"
                                    src={RealyLogo}
                                    alt="Realy"
                                />
                                <img
                                    className="hidden w-auto h-8 lg:block"
                                    src={RealyLogo}
                                    alt="Realy"
                                />
                            </div>
                            <div className="hidden md:block">
                                <div className="flex items-center space-x-4">
                                    {navigation.map(item => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-gray-900 text-white'
                                                    : 'text-white  hover:text-white',
                                                'px-3 py-2 font-sans text-normal font-medium'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}>
                                            <div className="flex">
                                                {t(`topMenu.${item.name}`)}
                                                {item.name === 'Airdrop' && (
                                                    <svg
                                                        width="6"
                                                        height="6"
                                                        viewBox="0 0 6 6"
                                                        fill="none"
                                                        className="ml-2"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle
                                                            cx="3"
                                                            cy="3"
                                                            r="3"
                                                            fill="#20EB7D"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                        </a>
                                    ))}
                                    {/* <select
                                        ref={select}
                                        onChange={changeLanguage}
                                        defaultValue={lng}
                                        className="w-16 px-2 py-1 text-white bg-black bg-opacity-0 border-0 rounded-md outline-none focus:outline-none focus:border-0 active:border-0">
                                        <option value="en">
                                            EN
                                        </option>
                                        <option value="zh">CN</option>
                                    </select> */}
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md bg-none focus:outline-none focus:ring-0">
                                                {t(`topMenu.${lng.toLowerCase()}`)}
                                                <ChevronDownIcon
                                                    className="-mr-1 ml-2 h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Menu.Items className="origin-top-right absolute w-full left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                onClick={() => changeLanguage('EN')}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-gray-100 text-gray-900'
                                                                        : 'text-gray-700',
                                                                    'block cursor-pointer px-4 py-2 text-sm hover:bg-yellow-r'
                                                                )}>
                                                                {t('topMenu.en')}
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                onClick={() => changeLanguage('ZH')}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-gray-100 text-gray-900'
                                                                        : 'text-gray-700',
                                                                    'block cursor-pointer px-4 py-2 text-sm hover:bg-yellow-r'
                                                                )}>
                                                                {t('topMenu.zh')}
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>

                                    <a
                                        href="/whitepaper.pdf"
                                        className="px-6 py-3 font-semibold text-black rounded-full bg-yellow-r hover:bg-yellow-hover">
                                        {t('topMenu.whitePaper')}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center md:hidden">
                            <Disclosure.Button className="flex items-center justify-center p-2 font-semibold text-white rounded-md hover:text-white md:hidden">
                                <span className="sr-only">Open main menu</span>
                                <div
                                    className={`tham tham-e-squeeze tham-w-8 ${
                                        open ? 'tham-active' : ''
                                    }`}>
                                    <div className="tham-box">
                                        <div className="tham-inner bg-white" />
                                    </div>
                                </div>
                                {/* {open ? (
                                    <XIcon className="block w-7 h-7" aria-hidden="true" />
                                ) : (
                                    <MenuIcon className="block w-7 h-7" aria-hidden="true" />
                                )} */}
                            </Disclosure.Button>
                        </div>
                    </div>
                    {/* only show on mobile */}
                    <Disclosure.Panel className="h-screen bg-black lg:hidden">
                        <div className="px-4 pt-2 pb-3 space-y-1">
                            <div className="flex justify-between px-2 pt-4 pb-8 border-b border-white border-opacity-10">
                                {showSocialList.map(social => (
                                    <div
                                        key={social}
                                        className="flex items-center justify-center w-10 h-10 bg-white rounded-full bg-opacity-10">
                                        <a
                                            href={SocialLinks[social]}
                                            target="__blank"
                                            className="social-btn">
                                            <SocialIcon name={social} />
                                        </a>
                                    </div>
                                ))}
                            </div>
                            {navigation.map(item => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={classNames(
                                        item.current
                                            ? 'bg-gray-900 text-white'
                                            : 'text-white  hover:text-gray-50',
                                        'block px-3 py-6 text-base border-white border-opacity-10 border-b '
                                    )}
                                    aria-current={item.current ? 'page' : undefined}>
                                    <div className="flex">
                                        {t(`topMenu.${item.name}`)}
                                        {item.name === 'airdrop' && (
                                            <svg
                                                width="6"
                                                height="6"
                                                viewBox="0 0 6 6"
                                                fill="none"
                                                className="ml-2"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#20EB7D" />
                                            </svg>
                                        )}
                                    </div>
                                </Disclosure.Button>
                            ))}
                            <div>
                                {/* <label className="flex items-center py-3 pl-3 border-b-2 border-white border-opacity-10">
                                    <select
                                        ref={smselect}
                                        onChange={smchangeLanguage}
                                        defaultValue={lng}
                                        className="
                                            block
                                            flex-1
                                            text-white bg-black bg-opacity-0
                                            mt-0
                                            px-0.5
                                            border-0
                                            focus:ring-0 focus:border-black
                                        ">
                                        <option value="en">
                                            EN
                                        </option>
                                        <option value="zh">CN</option>
                                    </select>
                                </label> */}
                                <Menu
                                    as="div"
                                    className="relative inline-block text-left w-full py-4 border-b-2 border-white border-opacity-10">
                                    <div>
                                        <Menu.Button className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-white rounded-md bg-none focus:outline-none focus:ring-0">
                                            {t(`topMenu.${lng.toLowerCase()}`)}
                                            <ChevronDownIcon
                                                className="-mr-1 ml-2 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items className="origin-top-right absolute w-full left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={() => changeLanguage('EN')}
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-100 text-gray-900'
                                                                    : 'text-gray-700',
                                                                'block cursor-pointer px-4 py-2 text-sm hover:bg-yellow-r'
                                                            )}>
                                                            {t('topMenu.en')}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={() => changeLanguage('ZH')}
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-100 text-gray-900'
                                                                    : 'text-gray-700',
                                                                'block cursor-pointer px-4 py-2 text-sm hover:bg-yellow-r'
                                                            )}>
                                                            {t('topMenu.zh')}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center mt-10">
                            <a
                                onClick={downLoadModal}
                                className="py-3 mb-6 text-lg font-semibold text-center text-black border-2 rounded-full w-60 border-yellow bg-yellow md:ml-10">
                                {t('topMenu.download')}
                            </a>
                            <a
                                href="/whitepaper.pdf"
                                className="py-3 mb-6 text-lg font-semibold text-center text-black border-2 rounded-full w-60 border-yellow bg-yellow md:ml-10">
                                {t('topMenu.whitePaper')}
                            </a>
                        </div>
                        <Download open={showDownLoadModal} toggleModal={downLoadModal} />
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
