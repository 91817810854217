import Details08 from './Details08'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
// import { isMobile } from 'react-device-detect'
import SwiperCore, { Navigation } from 'swiper'
// import raodmap1 from '../assest/raodmap1.svg'
import { useTranslation } from 'react-i18next'
// import ramap_right from '../assest/ramap_right.svg'
// import ramap_left from '../assest/ramap_left.svg'
SwiperCore.use(Navigation)
const FDetails08 = () => {
    const { t } = useTranslation('screen08')
  

    return (
        <div className="f-details08-warp">
            <Swiper
                id="mySwiper"
                className="mySwiper08"
                spaceBetween={40}
                slidesPerView={'auto'}
                navigation={{ nextEl: '#nextBtn', prevEl: '#prevBtn' }}>
                {[...new Array(5).keys()].map((v) => {
                    return (
                        <SwiperSlide key={`item${v}`} style={{ width: 320, height: 400 }}>
                            <Details08
                                title={t(`itemDate${v + 1}`)}
                                // img={raodmap1}
                                description={t(`itemText${v + 1}`)}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className="w-full flex justify-center lg:justify-end lg:w-1200 mx-auto space-x-12 lg:space-x-7 pt-11 navigation-box 3xl:hidden ">
                <button id="prevBtn" className="w-12 h-12 p-1 rounded-full bg-white bg-opacity-10 lg:w-auto lg:h-auto lg:p-1.5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="lg:h-6 lg:w-6 opacity-60"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </button>
                <button id="nextBtn" className="w-12 h-12 p-1 rounded-full bg-white bg-opacity-10 lg:w-auto lg:h-auto lg:p-1.5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="lg:h-6 lg:w-6 opacity-60"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                        />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default FDetails08
