import '../css/details08.css'
import details08 from '../assest/details08.svg'
const Details08 = ({ title, description }) => {
    return (
        <div className="w-full h-full cursor-grab box-border bg-white bg-opacity-10 rounded-2xl px-5">
            <div>
                <div
                    style={{ color: '#00FFA3',fontSize: "24px", lineHeight:"28px",weight:"500" }}
                    className="text-left font-medium mt-0.5 pt-14 ">
                    {title}
                </div>
                <div className="pt-8">
                    <img className="" alt="" src={details08} />
                </div>
                <div className="text-left pt-5 text-base text-white text-opacity-80 leading-7" style={{lineHeight: '149%'}}>
                    {description}
                </div>
            </div>
        </div>
    )
}

export default Details08
