import { useState, useEffect } from 'react'
import image from './assest/image94.png'
import ScreenWrapper from './ScreenWrapper'
import Player from '../components/Player'
import './css/screen02.css'
import { useTranslation } from 'react-i18next'
import QueueAnim from 'rc-queue-anim'
const Screen02 = ({ fullpageclass, index, currentIndex }) => {
    const { t } = useTranslation('screen02')
    const [isShow, setDisplayVideo] = useState(false)

    const toggleModal = () => {
        setDisplayVideo(!isShow)
    }
    // const show = (e) => {
    //     e.stopPropagation()
    //     setDisplayVideo(true)
    // }
    // const hide = () => {
    //     console.log('hide')
    //     setDisplayVideo(false)
    // }
    const [showAnimation, setShowAnimation] = useState(false)
    useEffect(() => {
        if (index === currentIndex) {
            setShowAnimation(true)
        } else {
            setShowAnimation(false)
        }
    }, [currentIndex, index])

    // const toggleModal = () => {
    //     setDisplayVideo(!isShow)
    // }

    return (
        <ScreenWrapper id="screen02" fullpageclass={fullpageclass}>
            <div className="screen02-warp">
                {index !== currentIndex ? (
                    <div className="screen02-layout opacity-0 pb-20">
                        <div className="screen02-layout-left" onClick={toggleModal}>
                            <div className="screen02-img screen02-layout-left-warp">
                                <img
                                    src={image}
                                    alt=""
                                    key="img"
                                    className="screen02-layout-left-img"
                                />
                                <div className="screen02-layout-left-svg">
                                    <svg
                                        className="screen02-svg"
                                        width="44"
                                        height="45"
                                        viewBox="0 0 44 45"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M22 44.5C34.1503 44.5 44 34.6503 44 22.5C44 10.3497 34.1503 0.5 22 0.5C9.84974 0.5 0 10.3497 0 22.5C0 34.6503 9.84974 44.5 22 44.5ZM19.0429 31.6404L31.2074 24.2066C32.4836 23.4267 32.4836 21.5733 31.2074 20.7934L19.0429 13.3596C17.7102 12.5451 16 13.5043 16 15.0661V29.9339C16 31.4957 17.7102 32.4549 19.0429 31.6404Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="screen02-layout-right">
                            <div className="screen02-layout-right-warp" key="right-warp">
                                <h1 className="screen02-layout-right-title">{t('title')}</h1>
                                <p className="screen02-layout-right-text1 ">{t('text1')}</p>

                                <p className="screen02-layout-right-text2 ">{t('text2')}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="screen02-layout" key="screen02">
                        <div className="screen02-layout-left" onClick={toggleModal}>
                            <div className="screen02-img screen02-layout-left-warp">
                                <QueueAnim
                                    className="screen02-layout-left-img"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [0, 50] },
                                        { opacity: [1, 0], translateY: [0, -50] }
                                    ]}
                                    ease={['easeOutQuart', 'easeInOutQuart']}>
                                    {showAnimation ? (
                                        <img
                                            src={image}
                                            alt=""
                                            key="img"
                                            className="screen02-layout-left-img"
                                        />
                                    ) : null}
                                </QueueAnim>
                                <QueueAnim
                                    delay={200}
                                    animConfig={[{ opacity: [1, 0] }, { opacity: [1, 0] }]}
                                    ease={['easeOutQuart', 'easeInOutQuart']}>
                                    {showAnimation ? (
                                        <div key="playbtn">
                                            <div className="screen02-layout-left-svg">
                                                <svg
                                                    className="screen02-svg"
                                                    width="44"
                                                    height="45"
                                                    viewBox="0 0 44 45"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M22 44.5C34.1503 44.5 44 34.6503 44 22.5C44 10.3497 34.1503 0.5 22 0.5C9.84974 0.5 0 10.3497 0 22.5C0 34.6503 9.84974 44.5 22 44.5ZM19.0429 31.6404L31.2074 24.2066C32.4836 23.4267 32.4836 21.5733 31.2074 20.7934L19.0429 13.3596C17.7102 12.5451 16 13.5043 16 15.0661V29.9339C16 31.4957 17.7102 32.4549 19.0429 31.6404Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    ) : null}
                                </QueueAnim>
                            </div>
                        </div>
                        <div className="screen02-layout-right">
                            <QueueAnim
                                animConfig={[
                                    { opacity: [1, 0], translateY: [0, 50] },
                                    { opacity: [1, 0], translateY: [0, -50] }
                                ]}
                                ease={['easeOutQuart', 'easeInOutQuart']}>
                                {showAnimation ? (
                                    <div className="screen02-layout-right-warp" key="right-warp">
                                        <h1 className="screen02-layout-right-title">
                                            {t('title')}
                                        </h1>
                                        <p className="screen02-layout-right-text1 ">{t('text1')}</p>

                                        <p className="screen02-layout-right-text2 ">{t('text2')}</p>
                                    </div>
                                ) : null}
                            </QueueAnim>
                        </div>
                    </div>
                )}
            </div>
            <Player
                key="aa"
                open={isShow}
                toggleModal={toggleModal}
                url="https://realy.4everland.store/Launch_Kooola_Virtual_City_NEW.mp4"
            />
        </ScreenWrapper>
    )
}

export default Screen02
