import { Fragment, useState } from 'react'
import classnames from 'classnames'
import './style.css'
import SocialIcon from '../SocialIcon'
import arrowLeftSvg from '../../assets/icons/arrow_left.svg'
import arrowRightSvg from '../../assets/icons/arrow_right.svg'

const socialLinks = {
    twitter: 'https://twitter.com/RealyOfficial',
    discord: 'https://discord.gg/realyofficial',
    telegram: 'https://t.me/realyofficial',
    instagram: 'https://www.instagram.com/realy.pro/',
    medium: 'https://medium.com/@realy'
}

const SocialsButtons = () => {
    const [expand, setExpand] = useState(false)
    const [showSocialList, setShowSocialList] = useState(['twitter'])

    const toggleExpand = (expandFlag) => {
        setExpand(expandFlag)
        if (!expandFlag) {
            setShowSocialList(['twitter'])
        } else {
            setShowSocialList(['twitter', 'discord', 'medium', 'telegram', 'instagram'])
        }
    }

    return (
        <>
            <div
                className="hidden wrapper md:flex"
                onMouseLeave={() => toggleExpand(false)}
                onMouseEnter={() => toggleExpand(true)}>
                <div className={classnames('social-btn-wrap', { expand })}>
                    {showSocialList.map((social) => (
                        <Fragment key={social}>
                            <a href={socialLinks[social]} target="__blank" className="social-btn">
                                <SocialIcon name={social} />
                            </a>
                        </Fragment>
                    ))}
                </div>
                <div className={classnames('expand-btn', { expand })}>
                    <img src={!expand ? arrowRightSvg : arrowLeftSvg} alt="" />
                </div>
            </div>
        </>
    )
}

export default SocialsButtons
