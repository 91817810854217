import { useState, useEffect, useRef } from 'react'
import ScreenWrapper from './ScreenWrapper'
import './css/screen05.css'
// import vector from './assest/vector.svg'
import vote from '../assets/icons/vote@2x.png'
import properties_svg from '../assets/icons/land@2x.png'
// import nft from './assest/nft.svg'
import earn from '../assets/icons/earn@2x.png'
import plus_circle from './assest/plus-circle.svg'
import token_ecomry from '../assets/images/token_eco.png'
import real_reward from '../assets/icons/reward@2x.png'
import treasury from '../assets/icons/Treasury@2x.png'
import city_events from '../assets/icons/events@2x.png'
import { useTranslation } from 'react-i18next'
import QueueAnim from 'rc-queue-anim'
const Screen05 = ({ fullpageApi, fullpageclass, index, currentIndex }) => {
    const [isShow, setDisplayVideo] = useState(false)
    const [showAnimation, setShowAnimation] = useState(false)
    const show = () => setDisplayVideo(true)
    const hide = () => setDisplayVideo(false)
    const { t } = useTranslation('screen05')
    const wrap = useRef(null)
    const dataList = [
        {
            title: '$REAL token',
            desc:
                'The $REAL token is released in the form of gradual release of inflation, which is mainly used to reward various behaviors and activities in the metaverse.',
            icon: real_reward
        },
        {
            title: 'Treasury',
            desc:
                'The Realy Metaverse treasury releases $REAL every day. Part of which is released to daily tasks, and the other part is injected into City DAOs. Different cities will have competitions.',
            icon: treasury
        },
        {
            title: 'Land owning and renting',
            desc:
                'Players who hold land NFT assets automatically obtain voting rights for the City DAO. Land can be leased to creators to run businesses, and the rent is determined by the market supply and demand.',
            icon: properties_svg
        },
        {
            title: 'Store operation',
            desc:
                'Owning or renting a NFT can open a store in the virtual city. Both the land owners and operators will get  $REAL rewards based on the activities and transaction volume of the store.',
            icon: earn
        },
        {
            title: 'Staking to Vote',
            desc:
                'City players can stake $REAL to get voting rights and get staking rewards at the same time.',
            icon: vote
        },
        {
            title: 'City events',
            desc:
                'The city regularly organizes or co-brands city events. And participating users can get $REAL rewards.',
            icon: city_events
        }
    ]
    useEffect(() => {
        if (index === currentIndex) {
            setShowAnimation(true)
            // fullpageApi.reBuild()
            // console.log(fullpageApi)
        } else {
            setShowAnimation(false)
        }
    }, [currentIndex, index, fullpageApi])
    // fullpageApi && fullpageApi.reBuild()
    // useEffect(() => {
    //     // console.log(wrap)
    //     // if (wrap.current) {
    //     //     console.log(3333)
    //     //     console.log(fullpageApi)
    //     //     // wrap.current.clientHeight = 3000
    //     //     fullpageApi && fullpageApi.reBuild()
    //     // }
    //     if (fullpageApi){
    //         console.log(fullpageApi)
    //         fullpageApi.reBuild()
    //         console.log('rebuild')
    //     }
    // }, [fullpageApi])
    return (
        <ScreenWrapper fullpageclass={fullpageclass}>
            <div className="screen05-warp" ref={wrap} id="screen04">
                {index !== currentIndex ? (
                    <div className="opacity-0">
                        <h1 className="screen05-warp-h1">{t('title')}</h1>
                        <div className="screen05-warp-title-item">
                            {dataList.map(({ icon, title }, i) => (
                                <div
                                    key={`${title}${i}`}
                                    className={`screen05-warp-item-list md:h-180`}>
                                    <img className="screen05-warp-item-img" src={icon} alt="" />
                                    <h1 className="screen05-warp-item-h1">
                                        {t(`itemTitle${i + 1}`)}
                                    </h1>
                                    <div className="screen05-warp-item-div">
                                        {t(`itemText${i + 1}`)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="screen05-warp-show-button" onClick={show}>
                            {t('subtitle')}
                            <img
                                className="screen05-warp-show-button-img"
                                src={plus_circle}
                                alt=""></img>
                        </div>
                    </div>
                ) : (
                    <QueueAnim
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 50] },
                            { opacity: [1, 0], translateY: [0, -50] }
                        ]}
                        ease={['easeOutQuart', 'easeInOutQuart']}>
                        {showAnimation ? (
                            <div key="items">
                                <h1 className="screen05-warp-h1">{t('title')}</h1>
                                <div className="screen05-warp-title-item">
                                    {dataList.map(({ icon, title }, i) => (
                                        <div
                                            key={`${title}${i}`}
                                            className={`screen05-warp-item-list md:h-180`}>
                                            <img
                                                className="screen05-warp-item-img"
                                                src={icon}
                                                alt=""
                                            />
                                            <h1 className="screen05-warp-item-h1">
                                                {t(`itemTitle${i + 1}`)}
                                            </h1>
                                            <div className="screen05-warp-item-div">
                                                {t(`itemText${i + 1}`)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="screen05-warp-show-button" onClick={show}>
                                    {t('subtitle')}
                                    <img
                                        className="screen05-warp-show-button-img"
                                        src={plus_circle}
                                        alt=""></img>
                                </div>
                            </div>
                        ) : null}
                    </QueueAnim>
                )}
            </div>
            {isShow && (
                <div className="screen05-economy-warp">
                    <div className="screen05-economy-layout">
                        <img className="" src={token_ecomry} alt="" />

                        <button className="screen05-economy-button" onClick={hide}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8 md:w-10 md:h-10"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </ScreenWrapper>
    )
}

export default Screen05
