import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import Screen01 from './screen/Screen01'
import Screen02 from './screen/Screen02'
import Screen03 from './screen/Screen03'
// import Screen04 from './screen/Screen04'
import Screen05 from './screen/Screen05'
import Screen06 from './screen/Screen06'
import Screen07 from './screen/Screen07'
import Screen08 from './screen/Screen08'
import SocialsButtons from './components/SocialsButtons'
import TopMenu from './components/TopMenu'

import './App.css'
import './themes/animate.css'


const SEL = 'custom-section'
const SECTION_SEL = `.${SEL}`

const originalColors = ['#000', '#000', '#000', '#000', '#000', '#000', '#000']

const pagesComponents = [
    Screen01,
    Screen02,
    Screen03,
    // Screen04,
    Screen05,
    Screen06,
    Screen07,
    Screen08
]

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sectionsColor: [...originalColors],
            currentIndex: 0
        }
    }

    afterLoad(origin, destination) {
        this.setState({
            currentIndex: destination.index
        })
        // const doc = document.querySelector(`#screen0${destination.index + 1 }`)
        // window.fullpage_api.reBuild()
        // const pageDom = window.document.getElementsByClassName('page-' + destination.index)[0]
        // const animateDoms = pageDom.getElementsByClassName('animated')
        // if (animateDoms) {
        //     for (let dom of animateDoms) {
        //         dom.classList.add('animate-into')
        //     }
        // }
    }

    // afterSlideLoad(section, origin, destination, direction) {
    // }

    // afterRender(e, r) {
    // }

    onLeave(origin, destination) {
        // console.log(origin,direction);
        // this.setState({
        //     currentIndex: destination.index
        // })
        // arguments are mapped in order of fullpage.js callback arguments do something
        // with the event

        // const pageDom = window.document.getElementsByClassName('page-' + destination.index)[0]
        // const animateDoms = pageDom.getElementsByClassName('animated')
        // if (animateDoms) {
        //     for (let dom of animateDoms) {
        //         dom.classList.remove('animate-into')
        //     }
        // }
        // const pageDomPre = window.document.getElementsByClassName(
        //     `page-${destination.index - 1}`
        // )[0]

        // const pageDomNext = window.document.getElementsByClassName(
        //     `page-${destination.index + 1}`
        // )[0]

        
        // console.log(pageDomPre,pageDom,pageDomNext)

        // if (pageDom) {
        //     const animateDoms = pageDom.getElementsByClassName('animated')
        //     // console.log('animateDoms', animateDoms)

        //     if (animateDoms) {
        //         for (let dom of animateDoms) {
        //             dom.classList.remove('animate-into')
        //         }
        //     }
        // }

        // if (pageDomPre) {
        //     const animateDomsPre = pageDomPre.getElementsByClassName('animated')
        //     // console.log('animateDomsPre', animateDomsPre)

        //     if (animateDomsPre) {
        //         for (let dom of animateDomsPre) {
        //             dom.classList.remove('animate-into')
        //         }
        //     }
        // }

        // if (pageDomNext) {
        //     const animateDomsNext = pageDomNext.getElementsByClassName('animated')
        //     // console.log('animateDomsNext', animateDomsNext)

        //     if (animateDomsNext) {
        //         for (let dom of animateDomsNext) {
        //             dom.classList.remove('animate-into')
        //         }
        //     }
        // }
    }

    render() {

        return (
            <div className="App">
                <TopMenu  open={true}/>
                <ReactFullpage
                    // Required when using extensions
                    // pluginWrapper={pluginWrapper}
                    // fullpage options
                    // scrollingSpeed = {1000}
                    anchors = {['HOME', 'INTRO', 'REALY', 'TOKEN-ECONOMY', 'PARTERSHIP', 'INVESTOR', 'ROADMAP']}
                    licenseKey={'364A8C92-88ED48FD-A66C34A7-DC74DD95'}
                    navigationTooltips={['Home', 'Intro', 'Realy', 'Token Economy', 'Partership', 'Investor', 'Roadmap']}
                    navigation
                    navigationPosition="left"
                    sectionSelector={SECTION_SEL}
                    // onLeave={this.onLeave.bind(this)}
                    afterLoad={this.afterLoad.bind(this)}
                    // afterRender={this.afterRender.bind(this)}
                    // afterSlideLoad={this.afterSlideLoad.bind(this)}
                    sectionsColor={this.state.sectionsColor}
                    scrollOverflow={true}
                    render={({fullpageApi}) => (
                        <ReactFullpage.Wrapper>
                            {pagesComponents.map((Page, i) => (
                                <Page fullpageApi={fullpageApi} key={`page${i}`} fullpageclass={[SEL, `page-${i}`]} currentIndex={this.state.currentIndex} index={i}/>
                            ))}
                        </ReactFullpage.Wrapper>
                    )}
                />
                <SocialsButtons />
            </div>
        )
    }
}

export { App }
